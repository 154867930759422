import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { RolePermissionService } from '../services/permission/role-permission.service';
import { PDADCountryService } from "../services/PDAD/pdad-country.service";
import { PDADSection } from '../../app/constants/constants';
import { Router } from '@angular/router';
import { KnowledgeSharingSection } from '../../app/constants/constants';
import { environment, redirectionUrl } from '../../environments/environment';
import { RegionService } from 'src/app/knowledge-sharing/services/Region/region.service';
import { VirtualWorkspaceSection } from '../../app/constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  sectionConstant: any;
  landingPageInnovativeInitiative: any;
  sectionConstantKnowledgeSharing: any;
  searchText: string = '';
  public countries: Array<{ countryName: string; myIOMCountryCode: string; mgiCountryCode: string; iomRegionCode: string }>;
  public filteredCountry: any;
  public resettingCountries: any;
  showSubMenuVirtualWorkspace: boolean = false;
  showSubMenuKnowledgeSharing: boolean = false;
  showSubMenuLessonsLearnedAnalysisData: boolean = false;
  showSubMenuCountry: boolean = false;
  showSubMenuRegion: boolean = false;
  regionList: any[] = [];
  parentRegions: any[] = [];  // Filtered parent regions
  public filteredRegion: any;
  menuItems$: Observable<any>;
  menuItems: any[] = [];
  filteredMenuItem: any;
  userInfo: any;
  isAdminRequired: boolean = false;
  selectSearchFilter: number = 1;//0;
  sectionConstantVirtualWorkspace: any;
  searchInText: string = '';
  @Input() isAdmin: false;

  constructor(private roleService: RolePermissionService, private PDADCountryService: PDADCountryService, private regionService: RegionService, private router: Router) {
    this.sectionConstant = PDADSection;
    this.sectionConstantKnowledgeSharing = KnowledgeSharingSection;
    this.sectionConstantVirtualWorkspace = VirtualWorkspaceSection;
  }

  ngOnInit(): void {
    // this.searchInText = "";
    // this.selectSearchFilter = 0;
    this.landingPageInnovativeInitiative = environment.landingPageInnovativeInitiative;
    this.showSubMenuVirtualWorkspace = false;
    this.showSubMenuKnowledgeSharing = false;
    this.PDADCountryService.getCountries().subscribe(countries => {
      this.countries = countries.filter(c => c.countryName.toLowerCase() != ('Global'.toLowerCase()));
      //console.log(this.countries);
      this.resettingCountries = countries.filter(c => c.countryName.toLowerCase() != ('Global'.toLowerCase()));
    })
    this.regionService.getRegion().subscribe(
      (data) => {
        //this.regionList = data.filter(c => c.regionName.toLowerCase() != ('Global'.toLowerCase()));
        this.regionList = data.filter(c => c.parentRegion >= 0);
        // console.log(this.regionList);

        // Filter parent regions (those with parentRegion === 0)
        this.parentRegions = this.regionList.filter(r => r.parentRegion === 0);
        // console.log(this.parentRegions);
      }
    )
    this.menuItems$ = this.roleService.getMenuItems();
    this.menuItems$.subscribe(
      (data) => {
        this.menuItems = data;
        //console.log(this.menuItems);
        this.filteredMenuItem = this.menuItems.filter(c => c.menuItemName.toLowerCase() === ('Admin'.toLowerCase()));
        //console.log(this.filteredMenuItem);
        if (this.filteredMenuItem.length > 0) {
          this.isAdminRequired = true;
        } else {
          this.isAdminRequired = false;
        }
      }
    )
    // debugger                    
    //this.roleService.getUserRolePermissionAction().subscribe();    
  }

  getChildRegions(parentId: number) {
    return this.regionList.filter(r => r.parentRegion === parentId);
  }

  openSidebar() {
    const toggleMenu = document.querySelector('.toggle-menu');
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.toggle('showsidebar')
    toggleMenu.classList.toggle('active')
  }

  goToCountry(country: String, e): void {
    e.stopPropagation();
    this.showSubMenuCountry = false;
    if (country != null) {
      this.filteredCountry = this.countries.filter(c => c.countryName.toLowerCase() === (country.toLowerCase()));
      this.router.navigate(['../_pdad/pdad-country', this.sectionConstant.Country, this.filteredCountry[0].myIOMCountryCode]);
    }
  }

  public searchCountry() {
    this.filteredCountry = null;
    if (this.searchText.length > 2) {
      this.filteredCountries();
    } else
      this.countries = this.resettingCountries;
  }

  private filteredCountries() {
    var search = this.searchText.toLowerCase();
    this.countries = this.countries.filter(c => c.countryName.toLowerCase().includes(search));
  }

  public openCountrySubMenu(e) {
    e.stopPropagation();
    this.showSubMenuCountry = true;
    this.searchText = "";
    this.countries = this.resettingCountries;
  }

  public closeCountrySubMenu(e) {
    e.stopPropagation();
    this.showSubMenuCountry = false;
    this.searchText = "";
    this.countries = this.resettingCountries;
  }

  public openVirtualWorkspaceSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuVirtualWorkspace = true;
  }

  public closeVirtualWorkspaceSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuVirtualWorkspace = false;
  }

  public openKnowledgeSharingSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuKnowledgeSharing = true;
  }

  public closeKnowledgeSharingSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuKnowledgeSharing = false;
  }

  public openLessonsLearnedAnalysisDataSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuLessonsLearnedAnalysisData = true;
  }

  public closeLessonsLearnedAnalysisDataSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuLessonsLearnedAnalysisData = false;
  }

  goToRegion(region: String, e): void {
    e.stopPropagation();
    this.showSubMenuRegion = false;
    if (region != null) {
      this.filteredRegion = this.regionList.filter(c => c.regionName.toLowerCase() === (region.toLowerCase()));
      console.log(this.filteredRegion);
      this.router.navigate(['../_pdad/pdad-region', this.sectionConstant.Region, this.filteredRegion[0].iomRegionCode]);
    }
  }

  // goToGoodPractices(e): void {
  //   e.stopPropagation();
  //   this.router.navigate(['../knowledge-sharing/good-policy-practices', KnowledgeSharingSection.Good_Policy_Practices]);
  // }

  goToGoodPracticesAndReloadRoute() {
    let currentUrl = '/knowledge-sharing/good-policy-practices/' + KnowledgeSharingSection.Good_Policy_Practices;
    //this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  goToGlobal(e): void {
    e.stopPropagation();
    this.router.navigate(['../_pdad/pdad-global', this.sectionConstant.Global, environment.globalRegionCode]);
  }

  public openRegionSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuRegion = true;
  }

  public closeRegionSubMenu(e) {
    e.stopPropagation();
    this.showSubMenuRegion = false;
  }

  public filterSearch() {
    if (this.selectSearchFilter == 1) {
      this.router.navigate(['../virtual-workspace/workspace-list', this.sectionConstantVirtualWorkspace.Community_Of_Practice, this.searchInText]);
    }
    else if (this.selectSearchFilter == 2) {
      this.router.navigate(['../knowledge-sharing/good-policy-practices', this.sectionConstantKnowledgeSharing.Good_Policy_Practices], { queryParams: { st: this.searchInText } });
    }
    else if (this.selectSearchFilter == 3) {
      this.router.navigate(['../knowledge-sharing/resources', this.sectionConstantKnowledgeSharing.Resources, this.searchInText]);
    }
  }
}
